

























































































































import {
  TableColumn,
  TableComponent,
} from '@dnb-open-banking/vue-table-component';
import { TeamWithOrganizationDto } from '@portals/shared/admin/TeamDto';
import { format } from 'date-fns';
import Vue from 'vue';

import AdminPopUp from '@/components/AdminPopUp.vue';
import AdminSection from '@/components/AdminSection.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DnbButton from '@/components/DnbButton.vue';
import DnbWarning from '@/components/DnbWarning.vue';
import Loader from '@/components/Loader.vue';
import { deleteTeam, fetchTeam } from '@/service/teamService';

import { Breadcrumb } from '../../models/Breadcrumb';

type Data = {
  team: TeamWithOrganizationDto | null;
  loading: boolean;
  create: boolean;
  displayDeleteTeamPopUp: boolean;
  errorMessage: string;
};

export default Vue.extend({
  name: 'view-team',
  components: {
    Loader,
    AdminSection,
    TableComponent,
    TableColumn,
    DnbButton,
    AdminPopUp,
    DnbWarning,
    Breadcrumbs,
  },
  filters: {
    dateFormatter(date: Date) {
      if (!date) return 'N/A';
      return format(date, 'DD.MM.YYYY');
    },
  },
  data(): Data {
    return {
      team: null,
      loading: false,
      create: false,
      displayDeleteTeamPopUp: false,
      errorMessage: '',
    };
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      if (this.team && this.team.organization) {
        return [
          {
            name: 'Organizations',
            path: '/organizations',
          },
          {
            name: this.team.organization.name,
            path: `/organizations/${this.team.organization.id}`,
          },
          {
            name: this.team.name,
          },
        ];
      }
      return [];
    },
  },
  mounted() {
    this.loadTeam();
  },
  methods: {
    async loadTeam() {
      this.loading = true;
      this.team = await fetchTeam(this.$route.params.id);
      this.loading = false;
    },
    deleteTeamPopUp() {
      this.displayDeleteTeamPopUp = !this.displayDeleteTeamPopUp;
    },
    async confirmDeleteTeam() {
      try {
        await deleteTeam(this.$route.params.id);
        this.displayDeleteTeamPopUp = false;
        this.$router.push('/organizations/');
      } catch (error) {
        this.displayDeleteTeamPopUp = false;
        if (error.response) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = 'Something went wrong, please try again';
        }
      }
    },
  },
});
